.contact-main-container {
  display: flex;
  flex-direction: column;
}

.contact {
  margin-bottom: 24px;
}

.main-column-heading {
  margin-top: 30px;
  font-family: "Playpen Sans";
  font-size: 38px;
  color: #0183c7;
  text-align: center;
}

.contact-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #d5eef4;
  margin-top: 40px;
  margin-left: -76px;
  margin-right: -76px;
  padding-left: 80px;
  padding-right: 80px;
}

.contact-information {
  display: grid;
  grid-template-columns: 231px 231px;
  grid-row: auto auto;
  grid-column-gap: 50px;
  grid-row-gap: 40px;
  text-align: center;
}

.contact-details-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 231px;
}

.bold-contact-text {
  margin: 0;
  padding-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
}

.contact-details-link {
  color: #0183c7;
  text-decoration: underline;
  font-size: 18px;
}

.contact-details-social-icons {
  margin-top: 30px;
  text-align: center;
}

.contact-details-social-icons a {
  margin: 0 10px;
  color: #000000;
  font-size: 2em;
  transition: color 0.3s;
}

.social-icon-fb :hover {
  color: #0183c7;
}

.social-icon-inst :hover {
  color: #d6249f;
}

.footer {
  padding-top: 18px;
  color: #3d6d87;
  font-size: 16px;
  font-weight: 600;
}

.map-wrapper {
  margin-bottom: 40px;
  height: 400px;
  width: 600px;
}

@media screen and (max-width: 1350px) {
  .contact-information {
    padding: 0;
  }
  .contact-information {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-row-gap: 25px;
    align-items: center;
  }
  .contact-details {
    justify-content: space-evenly;
  }
  .main-column-heading {
    font-size: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .contact-details {
    flex-direction: column;
  }
  .map-wrapper {
    width: 100%;
  }
  .map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  .main-column-heading {
    font-size: 30px;
  }
  .contact-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 30px;
    justify-content: center;
  }
}

@media screen and (max-width: 640px) {
  .contact-details {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .main-column-heading {
    font-size: 24px;
  }
}
