.nav-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-container {
  width: 270px;
  height: auto;
}

.logo-container img {
  max-width: 100%;
}

.main-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style-type: none;
}

.menu-list-item {
  margin-left: 30px;
  text-align: center;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
  font-weight: 600;
  color: black;
  cursor: pointer;
  text-decoration: none;
  z-index: 1000;
}

.menu-list-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 100%;
  background-color: #f8f8f8;
  transition: right 0.3s ease-in-out;
}

.menu-list-item:hover::before {
  right: 0;
  background-color: #0183c7;
  height: 2.5px;
}

.active-link {
  margin-left: 30px;
  text-align: center;
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 600;
  color: #0183c7;
}

.active-link:hover::before {
  right: 100%;
}

.close-menu {
  position: absolute;
  top: 35px;
  right: 35px;
  font-size: 32px;
  display: none;
  cursor: pointer;
}

.open-menu {
  font-size: 28px;
  margin: 10px;
  display: none;
  cursor: pointer;
}

@media (max-width: 1300px) {
  .menu-list-item {
    margin-left: 20px;
    font-size: 18px;
  }

  .logo-container {
    width: 200px;
    height: auto;
  }
}

@media (max-width: 1150px) {
  .main-menu {
    display: none;
  }
  .close-menu {
    display: block;
  }
  .open-menu {
    display: block;
  }
  .main-menu.active {
    margin: 0;
    padding: 0;
    height: 300px;
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #f6fdff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24),
      0 5px 10px 0 rgba(0, 0, 0, 0.19);
  }
  .menu-list-item:hover::before {
    right: 100%;
  }
  .menu-list-item:hover {
    color: #0183c7;
  }
}
