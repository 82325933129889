.app {
  max-width: 1300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f6fdff;
  margin: auto;
  border-radius: 26px;
  padding-left: 76px;
  padding-right: 76px;
  padding-top: 28px;
  padding-bottom: 18px;
}

.validationError {
  color: #f04134;
  font-size: 12px;
}

.button-primary {
  border-radius: 34px;
  border: none;
  padding: 15px 20px;
  background: #54cae2;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.3s;
  color: black;
}

.yellow {
  color: black;
  background: #ffe44f;
}

.button-primary:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24), 0 5px 10px 0 rgba(0, 0, 0, 0.19);
}

.main-content-heading {
  font-family: "Playpen Sans";
  font-size: 38px;
  color: #0183c7;
}

@media screen and (max-width: 640px) {
  .app {
    padding-left: 24px;
    padding-right: 24px;
  }
  .main-content-heading {
    font-size: 24px;
    margin-top: 50px;
  }
}

@media screen and (max-width: 400px) {
  .app {
    border-radius: 0px;
  }
}
