.bubble {
  position: absolute;
  background: linear-gradient(#ffff, #bceaf6);
  animation-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  animation-iteration-count: infinite;
}

@keyframes bubbleAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0);
  }
}
