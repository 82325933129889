.asso-main-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.items-list {
  padding-inline-start: 0px;
  padding-top: 30px;
  margin-block-start: 0em;
  margin-block-end: 0em;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.item-card {
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 500;
  width: 330px;
  padding: 24px;
  border-radius: 26px;
  background: rgb(200, 238, 248);
  background: linear-gradient(
    rgba(200, 238, 248, 1) 0%,
    rgba(246, 253, 255, 1) 82%
  );
}

.item-card-img-container {
  display: flex;
  align-items: center;
  height: 180px;
}

.item-card-upper img {
  height: 180px;
  width: auto;
}

.item-card:hover {
  scale: 1.05;
  transition: 0.3s all ease-in-out;
  cursor: pointer;
}

.item-card-heading {
  font-size: 20px;
  font-weight: 700;
}

.item-card-price {
  color: #036ba2;
  font-weight: 700;
}

.item-card-deposit {
  color: #000000;
  font-weight: 700;
  opacity: 0.7;
}

.item-image {
  width: 200px;
  height: auto;
}

.item-additional-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 2;
  padding-bottom: 50px;
}

.asso-upper-section-content-text {
  line-height: 20px;
}

@media screen and (max-width: 1255px) {
  .item-card {
    zoom: 0.9;
  }
}

@media screen and (max-width: 1155px) {
  .items-list {
    justify-content: space-evenly;
  }
}
