@import url("https://fonts.googleapis.com/css2?family=Playpen+Sans:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playpen+Sans:wght@100..800&display=swap");
html,
body {
  background-color: #0183c7;
  font-family: "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  font-size: 18px;
  /* font-variation-settings: 'wght' 625; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 20px;
}

* {
  box-sizing: border-box;
}

ul {
  display: block;
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
}

@media screen and (max-width: 768px) {
  html,
  body {
    padding: 10px;
  }
}

@media screen and (max-width: 400px) {
  html,
  body {
    padding: 0px;
  }
}
