.content-title {
  font-family: "Playpen Sans";
  font-size: 24px;
  color: #0183c7;
  margin-top: 25px;
  margin-bottom: 10px;
}

.content-text {
  margin-top: 0;
  line-height: 28px;
  max-width: 800px;
}

.main-content-list {
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-left: 30px;
}

.main-content-list > li {
  line-height: 30px;
}

.main-content-list > li::marker {
  font-size: 30px;
}

.regulation-bubbles-container {
  position: relative;
  display: flex;
  align-items: center;
}

.regulations-lower-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1245px) {
  .regulation-bubbles-container {
    display: none;
  }
}

@media (max-width: 500px) {
  .main-content-list > li::marker {
    font-size: 18px;
  }
  .main-content-list {
    padding-left: 20px;
  }
  .content-title {
    font-size: 22px;
  }
}
