.upper-form-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.form-img-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.form-img-container img {
  height: 300px;
  width: auto;
  z-index: 2;
}

.form-input-long {
  width: 654px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-input-medium {
  width: 315px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.form-input-short {
  width: 130px;
  margin-bottom: 8px;
}

.form-label {
  margin-bottom: 5px;
}

.calendar-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 10px;
  padding-top: 5px;
}

.rdrDateDisplayWrapper {
  background-color: rgb(232 248 252) !important;
}

.calendar {
  position: relative;
}

.calendar-content-disabled {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 332px;
  position: absolute;
  display: flex;
  text-align: center;
  font-weight: 700;
  align-items: center;
  background-color: rgb(202, 211, 211);
  z-index: 1000;
  opacity: 0.7;
}

.calendar-additional-text {
  display: flex;
  flex-direction: column;
  width: 270px;
  color: #0183c7;
}

.delivery-info-content {
  display: grid;
  grid-template-columns: 315px 315px;
  grid-row: auto auto;
  grid-column-gap: 24px;
}

.time-pickers-content {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.time-picker {
  display: flex;
  flex-direction: column;
  width: 315px;
}

.exception-delivery-text {
  margin-top: 18px;
  color: #0183c7;
}

.lower-form-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.checkbox-statement {
  padding-top: 30px;
  padding-bottom: 40px;
}

.form-img-container-small {
  display: none;
}

/* modal */
.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  padding: 50px;
  background-color: rgba(115, 113, 113, 0.568);
  z-index: 100000;
}

.modal-heading {
  font-family: "Playpen Sans";
  color: #0183c7;
}

.modal-content {
  background-color: #f6fdff;
  margin: 120px auto;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  width: 80%;
  border-radius: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24), 0 5px 10px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

.icons-list {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.icons-list a {
  margin: 0 10px;
  color: rgb(83, 80, 80);
  font-size: 28px;
  transition: color 0.3s;
}

.icon-email a {
  color: #df574b;
}

.icon-phone a {
  color: #24cc63;
}

.icon-fb a {
  color: #0183c7;
}

.icon-inst a {
  color: #d6249f;
}

.icon-email a:hover {
  color: #bd3427;
}

.icon-phone a:hover {
  color: #189648;
}

.icon-fb a:hover {
  color: #016aa3;
}

.icon-inst a:hover {
  color: #a71b7b;
}

.close-modal {
  padding-block: 0;
  padding-inline: 0;
  padding: 10px;
  border-width: 0;
  border-style: none;
  border-image: none;
  color: rgb(161, 158, 158);
  background-color: inherit;
}

.close-modal:hover {
  color: #0183c7;
  cursor: pointer;
}

@media (max-width: 1270px) {
  .form-img-container {
    zoom: 0.8;
  }
}

@media (max-width: 1200px) {
  .form-img-container {
    display: none;
  }
  .form-input-long {
    width: 100%;
  }
  .upper-form-fields {
    flex-grow: 1;
  }
  .form-input-medium {
    width: 100%;
  }
  .calendar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
  .calendar-container {
    flex-direction: column;
    flex-direction: column-reverse;
    gap: 5px;
  }
  .calendar-additional-text {
    width: 100%;
  }
  .calendar-additional-text p {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .form-img-container-small {
    display: flex;
    justify-content: center;
    flex-grow: 1;
  }
  .form-img-container-small img {
    height: 200px;
    width: auto;
  }
}

@media (max-width: 905px) {
  .delivery-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .time-pickers-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .calendar {
    display: flex;
    gap: 15px;
  }
  .form-img-container-small img {
    display: none;
  }
}

@media (max-width: 450px) {
  .calendar {
    zoom: 0.9;
  }
  .modal-content {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-top: 30px;
    margin-left: 0;
    margin-right: 0;
  }
  .modal-heading {
    font-size: 20px;
  }
  .additional-info {
    display: none;
  }
}
