.homepage-main-heading {
  color: #0183c7;
  max-width: 566px;
  font-family: "Playpen Sans";
  font-weight: 700;
  font-size: 48px;
}

.homepage-main-content-heading {
  font-family: "Playpen Sans";
  font-size: 38px;
  color: #0183c7;
}

.homepage-upper-section {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homepage-upper-section-content-text {
  padding-top: 10px;
  padding-bottom: 20px;
  max-width: 500px;
  line-height: 27px;
  font-weight: 400;
}

.homepage-lower-section {
  padding-top: 15px;
  padding-bottom: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.homepage-lower-section-heading {
  font-family: "Playpen Sans";
  padding-left: 20px;
  padding-bottom: 40px;
  color: #0183c7;
}

.homepage-lower-section-content-list > li::marker {
  font-size: 40px;
}


.homepage-lower-section-content-list {
  line-height: 30px;
  max-width: 450px;
}

.homepage-lower-section-content-contact {
  padding-top: 30px;
  padding-left: 50px;
}

.homepage-lower-section-content-subtext {

  line-height: 27px;
}

.homepage-lower-section-link {
  color: #0183c7;
  text-decoration: underline;
  font-weight: 600;
  font-size: 18px;
}

.homepage-img-container {
  position: relative;
  padding-top: 40px;
  display: flex;
  align-items: center;
}

.homepage-img-container img {
  z-index: 2;
  max-width: 100%;
}

.container-img-asso {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
}

@media screen and (max-width: 1200px) {
  .homepage-main-heading {
    font-size: 38px;
  }
  .homepage-img-container {
    zoom: 0.8;
  }
  .homepage-main-content-heading {
    font-size: 30px;
  }
}

@media screen and (max-width: 1090px) {
  .homepage-upper-section {
    display: flex;
    justify-content: center;
  }
  .homepage-img-container {
    display: none;
  }
  .homepage-lower-section {
    align-items: center;
    justify-content: flex-start;
  }
  .homepage-lower-section-content-list {
    max-width: 100%;
  }
  .homepage-lower-section-content-contact {
    padding-left: 0;
    padding-top: 2px;
  }
  .homepage-upper-section-content-text {
    max-width: 100%;
    text-align: left;
  }
  .container-img-asso {
    display: flex;
  }
  .container-img-asso img {
    max-width: 100%;
    max-height: 200px;
  }
}

@media screen and (max-width: 660px) {
  .homepage-main-heading {
    font-size: 24px;
  }
  .homepage-main-content-heading {
    font-size: 20px;
  }
  .homepage-lower-section-content-list > li::marker {
    font-size: 18px;
  }
  .homepage-lower-section-content-list {
    font-size: 18px;
    padding-inline-start: 20px;
  }
}
